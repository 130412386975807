import React from "react";
import { Image } from "react-bootstrap";
import Coming from "../assets/coming.png";

const Stores = () => {
  return (
    <>
      <div className="bg-stores">
        <Image className="img-fluid opacity-75" src={Coming} />
      </div>
    </>
  );
};

export default Stores;
