import React from "react";
import { Col, Row, Form, Button, Card, Nav } from "react-bootstrap";
import ContactImg from "../assets/contact.jpg";

function Contact() {
  return (
    <>
      <section className="login ">
        <Row className="mx-0 stud-test">
          <Col md={1}></Col>
          <Col md={10} className=" my-3">
            <Row className="border border-primary mx-0 px-0 rounded-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13465.900527589385!2d78.4869518274817!3d17.45382279724311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b4e0c6c01a7%3A0x498b08975d81745b!2sMedicoll%20Marketing%20Private%20Limited!5e0!3m2!1sen!2sin!4v1690804391580!5m2!1sen!2sin"
                height="400"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="px-0"
              ></iframe>
            </Row>
          </Col>
        </Row>
        <Row className="mx-0 stud-test">
          <Col md={1}></Col>
          <Col md={10} className=" my-3">
            <Row className="">
              <Col md={7} className=" shadow-lg bg-white rounded">
                <h3 className="px-3 pt-4 pb-3 text-center">
                  We'll get in touch with you
                </h3>
                <Form className="p-3">
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control placeholder="Full name" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control type="email" placeholder="Email id" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control type="tel" placeholder="Mobile number" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Subject" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Your query here..."
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Button className="w-100 bg-footer">Contact Me</Button>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={5} className="px-5 bg-footer shadow-lg pt-5 rounded">
                <h5 className="text-white pb-3">Contact Us</h5>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-map-marker-alt text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Address:</strong>&nbsp;Plot no 43, 1<sup>st</sup>{" "}
                      Floor Part, W.T.Road, Vikrampuri Colony, Picket,
                      Tirumalagiri, Hyderabad-500009, TS.
                    </p>
                  </Col>
                </Row>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-phone text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Phone:</strong>&nbsp;040 - 2309 2309
                    </p>
                  </Col>
                </Row>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-envelope text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Email:</strong>&nbsp;coll@medicollmarketing.com
                    </p>
                  </Col>
                </Row>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-globe-asia text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Website:</strong>
                      &nbsp;www.medicollmarketing.com
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Contact;
