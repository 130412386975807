import React from "react";
import AboutImg from "../assets/aboutus.jpg";
import { Col, Image, Row } from "react-bootstrap";
import AbtImg from "../assets/abt.png";
import Counter from "../components/Counter";

function Aboutus() {
  return (
    <>
      <div className="about">
        <Image src={AboutImg} className="img-fluid opacity-75" />
        <div className="container">
          <h3 className="text-white">About us</h3>
        </div>
      </div>
      <div className="container">
        <Row>
          <Col md={7} className="py-5">
            <h5 className="abt-head">
              Welcome to <b>MEDICOL MARKETING PRIVATE LIMITED</b>
            </h5>
            <p>
              Medicoll Marketing Private Limited constantly work towards
              ensuring access to high quality and affordable medicines to
              support patients in need, Which is the reason why, we have been
              trusted by health care professionals and patients across
              geographies. Our best quality range of medicines are formulated as
              per the industry standards and to keep at par with fight against
              existing / new diseases and ailments. Medicoll Marketing Private
              Limited shares an enduring commitment to advance healthcare
              solutions, so more patients may be able to live longer and
              healthier lives.
            </p>
            <Row>
              <Col className="text-center">
                <h2 className="fw-bolder abt-head">
                  <Counter number={10} />
                </h2>
                <p className="fw-bold text-secondary">YEARS OF EXPERIENCED</p>
              </Col>
              <Col className="text-center">
                <h2 className="fw-bold abt-head">
                  <Counter number={2342} />
                </h2>
                <p className="fw-bold text-secondary">HAPPY CUSTOMERS</p>
              </Col>
              <Col className="text-center">
                <h2 className="fw-bold abt-head">
                  <Counter number={11} />
                </h2>
                <p className="fw-bold text-secondary">AWARD WINNING</p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Image src={AbtImg} />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col>
            <h5 className="fw-bolder history">
              Medicoll Marketing Private Limited History
            </h5>
            <p>
              We at Medicoll Marketing Private Limited manufacture and market a
              large basket of pharmaceutical formulations covering a broad
              spectrum of chronic and acute therapies. It includes generics,
              branded generics, ethical, specialty, complex or difficult to make
              technology-intensive products, over-the-counter (OTC), Active
              Pharmaceutical Ingredients (APIs) and Intermediates. Our broad
              portfolio of more than 24 high quality molecules covers multiple
              dosage forms, including tablets, capsules.
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Aboutus;
