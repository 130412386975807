import React from "react";
import { Col, Image, Nav, Row } from "react-bootstrap";
import Logo from "../assets/flogo.png";

function Footer() {
  return (
    <>
      <div className="bg-footer py-4">
        <div className="container">
          <Row className="mx-0 footer">
            <Col md={3}>
              <Image src={Logo} className="f-logo" />
              <p className="pt-2">
                <b>Medicoll Marketing Private Limited</b> constantly work
                towards ensuring access to high quality and affordable medicines
                to support patients in need, Which is the reason why, we have
                been trusted by health care professionals and patients across
                geographies.
              </p>
            </Col>
            <Col md={3}>
              <h4>Quick Links</h4>
              <Nav className="flex-column">
                <Nav.Link href="/" className="f-link">
                  <i className="fas fa-long-arrow-alt-right"></i>&nbsp; Home
                </Nav.Link>
                <Nav.Link href="About Us" className="f-link">
                  <i className="fas fa-long-arrow-alt-right"></i>&nbsp; About us
                </Nav.Link>
                <Nav.Link href="Team" className="f-link">
                  <i className="fas fa-long-arrow-alt-right"></i>&nbsp; Our Team
                </Nav.Link>
                <Nav.Link href="Products & Services" className="f-link">
                  <i className="fas fa-long-arrow-alt-right"></i>&nbsp; Products
                  & Services
                </Nav.Link>
                <Nav.Link href="Contact Us" className="f-link">
                  <i className="fas fa-long-arrow-alt-right"></i>&nbsp; Contact
                  us
                </Nav.Link>
              </Nav>
            </Col>
            <Col md={3}>
              <h4>Contact</h4>
              <Row>
                <Col md={1}>
                  <i className="fas fa-map-marker-alt"></i>
                </Col>
                <Col>
                  <address>
                    Plot no 43,1<sup>st</sup> Floor Part, W.T. Road, Vikrampuri
                    Colony, Picket, Tirumalagiri, Hyderabad - 500009, TS.
                  </address>
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <i className="fas fa-phone"></i>
                </Col>
                <Col>
                  <address>040 - 2309 2309</address>
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <i className="fas fa-envelope"></i>
                </Col>
                <Col>
                  <address>coll@medicollmarketing.com</address>
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <h4>Business Hours</h4>
              <h6 className="mb-0">Opening Days:</h6>
              <address>
                Monday - Saturday: <b>10am to 7pm</b>
              </address>
              <h6 className="mb-0">Vacations:</h6>
              <address>All Sundays & Public Holidays</address>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Footer;
