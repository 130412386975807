import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import Slider1 from "../assets/bg.jpg";
import Slider2 from "../assets/bg2.png";
import AboutImg from "../assets/abouts.png";
import Vision from "../assets/vision.png";
import Mission from "../assets/mission.png";
import Goals from "../assets/goal.png";
import Values from "../assets/values.png";

function Home() {
  return (
    <>
      <div className="">
        <Carousel>
          <Carousel.Item>
            <Image src={Slider1} className="slider img-slider" />
            <Carousel.Caption className="bg-caption">
              <h4 className="text-left">
                Welcome to Medicoll Marketing Pvt Ltd
              </h4>
              <p>
                Medicoll Marketing Private Limited constantly work towards
                ensuring access to high quality and affordable medicines to
                support patients in need, Which is the reason why, we have been
                trusted by health care professionals and patients across
                geographies.
              </p>
              <Button className="btn-medicoll">Order Now</Button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={Slider2} className="slider" />
            <Carousel.Caption className="bg-caption">
              <h4>
                Medicoll Marketing Pvt Ltd high quality and affordable medicines
              </h4>
              <p>
                Medicoll Marketing Private Limited constantly work towards
                ensuring access to high quality and affordable medicines to
                support patients in need, Which is the reason why, we have been
                trusted by health care professionals and patients across
                geographies.
              </p>
              <Button className="btn-medicoll">Order Now</Button>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="container">
        <Row className="mx-0 bg-light d-flex align-items-center">
          <Col lg={4}>
            <Image src={AboutImg} className="img-fluid" />
          </Col>
          <Col className="py-3">
            <p className="mb-0 pb-0 text-secondary">
              Welcome to Medicoll Marketing Pvt Ltd.
            </p>
            <h2 className="text-danger heading">About us</h2>
            <p>
              Medicoll Marketing Private Limited constantly work towards
              ensuring access to high quality and affordable medicines to
              support patients in need, Which is the reason why, we have been
              trusted by health care professionals and patients across
              geographies. Our best quality range of medicines are formulated as
              per the industry standards and to keep at par with fight against
              existing / new diseases and ailments. Medicoll Marketing Private
              Limited shares an enduring commitment to advance healthcare
              solutions, so that more patients may be able to live longer and
              healthier lives.
            </p>
          </Col>
        </Row>
      </div>
      <div className="bg-mission">
        <div className="container py-3">
          <Row>
            <Col md={2} className="py-3">
              <Image src={Vision} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Vision</h5>
              <p>
                Reaching people and touching lives as a leading provider of
                Valued Medicines.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="py-3">
              <Image src={Mission} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Mission</h5>
              <p>
                Recognizing biotechnology’s great potential to effectively treat
                rare, chronic and severe illnesses, we consistently work to
                ensure that patients in India / globally have direct access to
                innovative biotech therapies at affordable cost. To be the best
                of its own in top pharmaceutical companies, reach every
                milestone with committed efforts. Medicoll Marketing Pvt Ltd
                wants to add a new meaning to life and well being of most
                patients across India.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="py-3">
              <Image src={Goals} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Goal</h5>
              <p>
                Our Goal is to meet most healthcare needs of Patients with
                Metabolic Disorders covering maximum spectrum.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="py-3">
              <Image src={Values} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Values</h5>
              <p>
                Integrity: Our commitment to business ethics, fairness, honesty
                and transparency is equally important to us, as is to achieving
                business success. At the end of each day, we want to be proud
                not only for the goals we have achieved, but also for the way we
                have achieved them.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Home;
